import React, { useRef, useEffect, useState } from "react"
import DetailLogo from "../detail_logo"
import GetBodyItem from "../../../components/_helpers/_get_body_item"
import { Picture } from "react-responsive-picture"
import SliderArrow from "../../../components/sliders/slider_arrow"
import { CssClassManager } from "../../../components/_helpers/_css_class_manager"
import SliderManager from "../../../components/sliders/_slider_manager"

const ModelAdvantages = ({ _model, _pillars }) => {
  const sliderAdvantages = useRef(null)
  let pillars = _pillars
  let modelo = _model
  const bannerSectionRef = useRef(null)
  let getBodyItem = new GetBodyItem()
  let data = null
  const [slidesToShow, setSlidesToShow] = useState()
  let sliderArrow = new SliderArrow()
  const [direction, setDirection] = useState()
  const [isFortis, setIsFortis] = useState(false)

  if (pillars === undefined) {
    data = getBodyItem.get_item(modelo, "PrismicModelBodyVentajas")
  }

  useEffect(() => {
    if (modelo) {   
      let categoryModel = modelo.data.category.uid
      if (categoryModel === "fortis") {
        setIsFortis(true)
      }
    }
  }, [ modelo])

  useEffect(() => {
    const winWidth = window.innerWidth

    if (winWidth < 768) {
      setSlidesToShow(1)
    } else {
      if (winWidth >= 768 && winWidth < 1280) {
        setSlidesToShow(2)
      } else {
        if (winWidth >= 1280) {
          setSlidesToShow(3)
        }
      }
    }

    if (!bannerSectionRef.current) return
    sliderArrow.init(bannerSectionRef)

  }, [sliderArrow])

  useEffect(() => {
    let cssClassManager = new CssClassManager()
    setDirection(null)

    if (direction === "next") {
      cssClassManager.remove_class(sliderAdvantages.current, "on_prev")
      cssClassManager.add_class(sliderAdvantages.current, "on_next")
    } else if (direction === "prev") {
      cssClassManager.remove_class(sliderAdvantages.current, "on_next")
      cssClassManager.add_class(sliderAdvantages.current, "on_prev")
    }
  }, [direction])


  if (pillars !== undefined) { 
    function create_pillars() {
      return pillars.map((item, index) => {
        return (
          <div
            key={`carousel-${index}`}
            className="swiper-slide blog_carrousel_item"
          >
            <div className="detail_advantages_item_stage">
              <div className="detail_advantages_item_image_container">
                <Picture src={item.image} alt={"img"} />
              </div>
              <div className="detail_pillars_container">
                <div className="detail_pillars_item_content">
                  <div className="detail_advantages_item_icon">
                    <Picture src={item.icon} alt={"icon"} />
                  </div>
                  <div className="detail_advantages_item_text">
                    <div className="detail_advantages_item_text_title pillars_title">
                      {item.title}
                    </div>
                    <div className="detail_advantages_item_text_description pillars_description">
                      {item.description}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      })
    }

    return (
      <div
        className="detail_ventajas model_detail_section detail_pillars"
        ref={bannerSectionRef}
      >
        <div className="detail_ventajas_header">
          <DetailLogo _name={""} _title="Nuestros Pilares"></DetailLogo>
        </div>
        <div
          className="detail_ventajas_slider detail_pillars_slider"
          ref={sliderAdvantages}
        >
          <SliderManager _slidesToShow={slidesToShow}>
            {create_pillars()}
          </SliderManager>
        </div>
      </div>
    )
  }


  function create_items(_items) {
    return _items.map((_item, _index) => {
      return (
        <div
          key={`carousel-${_index}`}
          className="swiper-slide blog_carrousel_item"
        >
          <div className="detail_advantages_item_stage">
            <div className="detail_advantages_item_image_container">
              <Picture
                src={_item.advantage_image.url}
                alt={_item.advantage_image.alt}
              />
            </div>
            <div className={`detail_advantages_item_container ${isFortis ? "is-fortis" : ""}`}>
              <div className="detail_advantages_item_content">
                <div className="detail_advantages_item_icon">
                  <Picture
                    src={_item.advantage_icon.url}
                    alt={_item.advantage_icon.alt}
                  />
                </div>
                <div className="detail_advantages_item_text">
                  <div className="detail_advantages_item_text_title">
                    {_item.advantage_title.text}
                  </div>
                  <div className="detail_advantages_item_text_description">
                    {_item.advantage_description.text}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    })
  }

  return data && data.items !== null && (
    <div
      className="detail_ventajas model_detail_section"
      ref={bannerSectionRef}
    >
      <div className="detail_ventajas_header">
        <div className="detail_ventajas_header_container">
          <DetailLogo
            _name={modelo.data.model_name.html}
            _title="Ventajas"  
          ></DetailLogo>
        </div>
      </div>
      <div className="detail_ventajas_slider" ref={sliderAdvantages}>
        <SliderManager _slidesToShow={slidesToShow}>
          {create_items(data.items)}
        </SliderManager>
      </div>
    </div>
  )
  
}

export default ModelAdvantages
